import h from 'hyperscript';
import closeIcon from '@disy/cadenza-icons/close.svg';
import maximizeIcon from '@disy/cadenza-icons/maximize.svg';
import minimizeIcon from '@disy/cadenza-icons/minimize.svg';

import type { AlertType } from 'ui/alert/alert';
import type { ButtonVariant } from 'ui/button/button';
import { createButton, createIconButton } from 'ui/button/button';
import type { Content } from 'ui/content';
import { normalizeContent } from 'ui/content';
import { Dialog } from 'ui/dialog/dialog';
import 'ui/form-validation';
import { ProgressSpinner } from 'ui/progress-spinner/progress-spinner';
import 'ui/compact-header/compact-header.css';

import { addFormattedHotkeyToTooltip, addFormattedKeysToTooltip } from 'cadenza/hotkeys/hotkeys';
import type { EventKey } from 'cadenza/utils/event-util';
import { focusFirst, on, unByKey } from 'cadenza/utils/event-util';
import type { Icon } from 'cadenza/utils/icon/icon';
import { icon } from 'cadenza/utils/icon/icon';
import { mediaDialogXsOnly } from 'cadenza/utils/media';
import { setupScrollShadow } from 'cadenza/utils/scroll-shadow/scroll-shadow';
import { logger } from 'cadenza/utils/logging';
import { HOTKEYS } from 'cadenza/hotkeys/hotkey-definitions';
import { delay } from 'cadenza/utils/promise-utils';

import './modal.css';
import i18n from './modal.properties';

const shownModals: Modal<unknown>[] = [];

on(document, 'keydown', (event: KeyboardEvent) => {
  if (event.key === 'F11' && !(event.ctrlKey || event.shiftKey || event.metaKey)) {
    const modal = shownModals[0];
    if (modal && modal.querySelector('.fullscreen')) {
      event.preventDefault();
      modal.fullscreen = !modal.fullscreen;
    }
  }
});

const COMPONENT_NAME = 'd-modal';
const IS_STACKED = 'is-stacked';

const FULLSCREEN_TITLE = addFormattedKeysToTooltip(i18n('fullscreen'), 'F11');
const EXIT_FULLSCREEN_TITLE = addFormattedKeysToTooltip(i18n('exitFullscreen'), 'F11');

export type ModalSize = 's' | 'm' | 'l' | 'xl' | 'max';

interface LoadingOptions {
  label?: string;
  cancellable?: boolean;
  overlay?: boolean;
}

/**
 * @type R the type of the return value
 */
export interface ModalOptions<R = void> {
  titleIcon?: Icon;
  title?: string;
  size?: ModalSize;
  submitButton?: boolean | Content;
  cancelButton?: boolean | Content;
  cancelXButton?: boolean;
  fullscreenButton?: boolean;
  additionalButtons?: HTMLButtonElement[];
  content?: (modal: Modal<R>) => Promise<Content>;
}

interface AlertOptions {
  title?: string;
  message?: string;
  type?: AlertType;
}

export interface ModalConnectAndShowOptions {
  closeAll?: boolean;
  cancelAll?: boolean;
  stack?: boolean;
  // If set to true, the popup will go to fullscreen as soon as it displays
  fullscreen?: boolean;
}

/**
 * A modal dialog
 */
export class Modal<R = undefined> extends Dialog<R> {
  #fullscreen = false;
  #loading: boolean | Promise<unknown> = false;
  _mediaEventKey?: EventKey;

  protected readonly form: HTMLFormElement;
  protected readonly header: HTMLElement;
  #title?: Element;
  protected readonly body: HTMLElement;
  #alert?: Element;
  protected readonly footer: HTMLElement;
  #loadingFooter?: Element;
  #loadingOverlay?: Element;

  /**
   * Creates a new instance.
   *
   * @param options - Options
   * @param [options.titleIcon] - A title icon for the dialog
   * @param [options.title] - A title for the dialog
   * @param [options.size='m'] - The size of the dialog
   * @param [options.submitButton] - If truthy, a submit button is added. Can be set to just boolean true, or something that will be used as the button's label.
   * @param [options.cancelButton] - If truthy, a cancel button is added. Can be set to just boolean true, or something that will be used as the button's label.
   * @param [options.cancelXButton] - Whether to add a cancel "x" button. By default, the button is shown if there are no buttons in the footer.
   * @param [options.additionalButtons] - Additional buttons to be added in the footer.
   * @param [options.content] - An function that will asynchronously create some content for the modal. If specified, the result of the returned promise will be appended to the modal's body. Note: the function is asynchronous because in most cases, we want the content of the modal to be imported dynamically.
   */
  constructor(options: ModalOptions<R>) {
    super();

    this.header = this.createHeader(options.titleIcon, options.title, options.cancelXButton);
    this.body = h(`main.${COMPONENT_NAME}--body`);
    this.footer = h(`footer.${COMPONENT_NAME}--footer`);

    this.form = h(`form.${COMPONENT_NAME}--form`, [h('form-validation'), this.header, this.body, this.footer]);

    on(this.form, 'submit', (event: SubmitEvent) => {
      // Only if it's the dialog's form (e.g. no inline-editing form)
      if (this.form === event.target) {
        this.onSubmit(event);
      }
    });

    this.dialog.classList.add(COMPONENT_NAME);

    if (options.size) {
      this.dialog.classList.add(`${COMPONENT_NAME}-${options.size}`);
    }

    this.dialog.append(this.form);

    const submitButton = options.submitButton;
    if (submitButton) {
      this.addSubmitButton(submitButton === true ? undefined : submitButton);
    }

    const additionalButtons = options.additionalButtons;
    if (additionalButtons) {
      this.footer.append(...additionalButtons);
    }

    const cancelButton = options.cancelButton;
    if (cancelButton) {
      this.addCancelButton(cancelButton === true ? undefined : cancelButton);
    }

    if (options.fullscreenButton) {
      this.addFullScreenButton();
    }

    if (options.content) {
      const contentLoadingSpinner = new ProgressSpinner({ vertical: true, size: 's', initiallyHidden: true });
      const promise = delay(options.content(this), { useThreshold: true })
        .then((content) => {
          contentLoadingSpinner.replaceWith(...normalizeContent(content));
          focusFirst(this.body);
        })
        .catch((error) => {
          logger.error('Failed to create modal content', error);
          contentLoadingSpinner.remove();
        });
      contentLoadingSpinner.show(promise);
      this.body.append(contentLoadingSpinner);
    }

    on(this, 'cancel', (event) => {
      if (!this.querySelector('.cancel')) {
        // Cancelling a dialog is only supported if
        // there's a dedicated UI trigger for it
        // (i.e. Escape should not work otherwise).
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    });

    on(this, 'close', () => {
      this.resetAlert();
      this.setLoading(false);
    });

    on(
      this,
      'click',
      () => {
        this.fullscreen = !this.fullscreen;
      },
      { delegate: '.fullscreen' },
    );
  }

  override connectedCallback() {
    this.#applyFullscreen();
    this._mediaEventKey = on(mediaDialogXsOnly, 'change', () => this.#applyFullscreen());

    super.connectedCallback();
    setupScrollShadow(this.body);
  }

  override disconnectedCallback() {
    unByKey(this._mediaEventKey);
    super.disconnectedCallback();
  }

  /**
   * Connects the dialog to the DOM and shows it.
   *
   * The dialog is automatically disconnected again from the DOM when it's closed.
   *
   * @param [appendTo] - The parent node to append the dialog to
   * @return The return value of the dialog
   */
  connectAndShow(appendTo: Element): Promise<R>;
  /**
   * Connects the dialog to the DOM and shows it.
   *
   * - The dialog is connected either to an already open parent modal or to the `document.body`.
   * - The dialog is automatically disconnected again from the DOM when it's closed.
   *
   * @param [options]
   * @param [options.cancelAll] - Whether cancelling the dialog should also cancel the parent dialog (if any).
   * @param [options.closeAll] - Whether closing the dialog modal should also close the parent dialog (if any).
   * @param [options.stack=true] - Whether the parent dialog (if any) should be hidden while this dialog is shown.
   * @return The return value of the dialog
   */
  connectAndShow(options?: ModalConnectAndShowOptions): Promise<R>;
  connectAndShow(appendToOrOptions?: Element | ModalConnectAndShowOptions): Promise<R> {
    if (appendToOrOptions instanceof Element) {
      appendToOrOptions.append(this);
      return this.showModal().finally(() => this.remove());
    }

    const { closeAll = false, cancelAll = false, stack = true, fullscreen } = appendToOrOptions ?? {};
    if (fullscreen != null) {
      this.fullscreen = fullscreen;
    }
    const alreadyOpenModal = document.querySelector('.d-modal[open]:not([hidden], .closed)')?.parentElement as Modal;
    if (alreadyOpenModal) {
      const eventKeys: EventKey[] = [];
      if (cancelAll) {
        eventKeys.push(
          on(this, 'cancel', (event) => {
            if (!event.defaultPrevented) {
              alreadyOpenModal.cancel();
            }
          }),
        );
      }
      if (closeAll) {
        eventKeys.push(
          on(this, 'close', () => {
            alreadyOpenModal.closeAll(alreadyOpenModal.returnValue);
          }),
        );
      }

      this.dialog.classList.add(IS_STACKED);
      const promise = this.connectAndShow(alreadyOpenModal).finally(() => {
        unByKey(eventKeys);
        this.classList.remove(IS_STACKED);
        alreadyOpenModal.dialog.hidden = false;
      });
      alreadyOpenModal.dialog.hidden = stack;
      return promise;
    } else {
      return this.connectAndShow(document.body);
    }
  }

  /**
   * Shows the dialog
   *
   * @return The return value of the dialog
   * @deprecated Use {@link connectAndShow} instead
   */
  override showModal() {
    try {
      this.dialog.classList.add('closed');
      const result = super.showModal();
      focusFirst(this.body);
      return result;
    } finally {
      this.dialog.classList.remove('closed');
      shownModals.unshift(this);
    }
  }

  override close(returnValue?: R) {
    shownModals.shift();
    this.dialog.classList.add('closed');
    this.returnValue = returnValue;
    this.dispatchEvent(new CustomEvent('closing'));

    if (this.dialog.classList.contains(IS_STACKED)) {
      super.close(returnValue);
    } else {
      setTimeout(() => super.close(returnValue), 400); // duration of the CSS transition
    }
  }

  /**
   * Closes all modals of a modal stack.
   *
   * @param [returnValue] - An optional return value for this dialog
   * @see {@link #connectAndShow}
   */
  closeAll(returnValue?: R) {
    const parentNode = this.parentNode;
    if (parentNode instanceof Modal) {
      parentNode.closeAll();
    }
    this.close(returnValue);
  }

  /**
   * Adds a button to the header of the dialog
   *
   * @param button - the button to add
   */
  protected addHeaderButton(button: HTMLButtonElement) {
    const firstButton = this.header.querySelector('button');
    if (firstButton) {
      this.header.insertBefore(button, firstButton);
    } else {
      this.header.append(button);
    }
  }

  /**
   * Adds a fullscreen button to the dialog
   */
  protected addFullScreenButton() {
    const button = createIconButton(icon(maximizeIcon, { styleClass: 'maximize' }), FULLSCREEN_TITLE, {
      variant: 'borderless',
      size: 's',
      styleClass: ['fullscreen', 'hidden-xs'],
      tabIndex: -1,
    });
    button.append(icon(minimizeIcon, { styleClass: 'minimize' }));

    const closeXButton = this.header.querySelector('.cancel');
    if (closeXButton) {
      this.header.insertBefore(button, closeXButton);
    } else {
      this.header.append(button);
    }
  }

  /**
   * Adds a cancel "x" button to the dialog header.
   *
   * @deprecated Do not use this method, the Modal base class manages the cancel "x" button itself.
   */
  protected addCancelXButton() {
    if (!this.header.querySelector('.cancel')) {
      this.header.append(createCancelXButton());
    }
  }

  // Cancel button should be added to the header only if there are no buttons in the footer.
  /**
   * Removes the cancel "x" button from the dialog header.
   *
   * @deprecated Do not use this method, the Modal base class manages the cancel "x" button itself.
   */
  protected removeCancelXButton() {
    this.header.querySelector('.cancel')?.remove();
  }

  /**
   * Adds a submit button to the dialog footer
   *
   * @param [label='OK'] - The button label
   * @param options - Additional options used to customize submit button
   * @param [options.variant='primary'] - Variant of the button.
   */
  protected addSubmitButton(label: Content = i18n('ok'), { variant = 'primary' }: { variant?: ButtonVariant } = {}) {
    this.removeCancelXButton();
    this.footer.append(
      createButton(label, {
        submit: true,
        variant,
        'data-testid': 'submit-button',
        // Dispatch event before submit so containing inputs can set form-controls' custom validity
        onclick: () => this.dispatchEvent(new CustomEvent('pre-submit')),
      }),
    );
  }

  /**
   * Adds a cancel button to the dialog footer.
   *
   * @param [label='Cancel'] - The button label
   * @param options - Additional options used to customize cancel button
   * @param [options.variant=''] - Variant of the button.
   * @return The created cancel button.
   */
  protected addCancelButton(label: Content = i18n('cancel'), { variant }: { variant?: ButtonVariant } = {}) {
    this.removeCancelXButton();
    const cancelButton = createButton(label, { styleClass: 'cancel', variant });
    this.footer.append(cancelButton);
    return cancelButton;
  }

  /**
   * Activate or deactivate the loading mode of the modal.
   *
   * In loading mode, the modal footer is replaced with a loading spinner.
   *
   * @param loading - loading mode will de automatically deactivated when the given promise is
   *   resolved or rejected
   * @param [options] - Options or label
   * @param [options.label] - A label to show next to the loading spinner
   * @param [options.cancellable] - Whether a cancel button should be
   *   displayed besides the loading spinner
   * @param [options.overlay=true] - Whether a semi-transparent overlay will be displayed over the
   *   wizard body, preventing the user from interacting with elements while the dialog is loading.
   * @return Parameter 'loading' for optional chaining (if 'loading' was of type boolean
   *   convert this to a resolved promise returning this boolean)
   */
  setLoading<T>(loading: Promise<T>, options?: string | LoadingOptions): Promise<T>;
  /**
   * @deprecated use Promise-overload instead
   */
  setLoading(loading: boolean, options?: string | LoadingOptions): Promise<boolean>;
  setLoading<T>(loading: Promise<T> | boolean, options: string | LoadingOptions = {}): Promise<boolean> | Promise<T> {
    if (loading instanceof Promise && this.#loading === loading) {
      return loading;
    }
    if (typeof options === 'string') {
      options = { label: options };
    }
    const { label = '', cancellable, overlay = true } = options;

    const loadingBoolean = Boolean(loading);
    this.dialog.classList.toggle('is-loading', loadingBoolean);
    this.footer.hidden = loadingBoolean;

    this.#loadingFooter?.remove();
    this.#loadingFooter = undefined;
    this.#loadingOverlay?.remove();
    this.#loadingOverlay = undefined;

    if (loading) {
      this.#loadingFooter = h(`footer.${COMPONENT_NAME}--footer.${COMPONENT_NAME}--loading-footer`, [
        h(`d-progress-spinner.d-progress-spinner-xs.${COMPONENT_NAME}--loading-spinner`, { label }),
      ]);

      // Append the loading footer right after the normal footer wherever it is. This allows to
      // move the footer to a different position in the modal.
      this.footer.after(this.#loadingFooter);

      if (cancellable) {
        this.#loadingFooter.append(createButton(i18n('cancel'), { styleClass: 'cancel' }));
      }

      if (overlay) {
        this.#loadingOverlay = h(`.${COMPONENT_NAME}--overlay`);
        this.body.append(this.#loadingOverlay);
      }

      if (loading instanceof Promise) {
        loading.finally(() => {
          if (loading === this.#loading) {
            this.setLoading(false);
          }
        });
      }
      if (!this.#loading) {
        this.onLoadingStart();
      }
      this.#loading = loading;
    } else {
      if (label) {
        this.#loadingFooter = h(`footer.${COMPONENT_NAME}--footer.${COMPONENT_NAME}--loading-footer`, [
          h('span.progress-spinner-label', label),
        ]);
        this.form.append(this.#loadingFooter);
      }
      if (this.#loading) {
        this.onLoadingEnd();
      }
      this.#loading = false;
    }

    return loading instanceof Promise ? loading : Promise.resolve(loading);
  }

  isLoading() {
    return Boolean(this.#loading);
  }

  /**
   * @param text - The text of the header
   */
  protected set heading(text: string) {
    if (!this.#title) {
      this.#title = h(`h1.${COMPONENT_NAME}--title.d-compact-header--title`);
      this.header.append(this.#title);
    }
    this.#title.textContent = text === undefined ? '' : text;
  }

  protected get heading(): string {
    return this.#title?.textContent ?? '';
  }

  /**
   * Lifecycle method called after the loading started.
   */
  protected onLoadingStart() {
    /* Override me */
  }

  /**
   * Lifecycle method called after the loading ended.
   */
  protected onLoadingEnd() {
    /* Override me */
  }

  /**
   * Lifecycle method called after the modal has moved to/from fullscreen mode.
   */
  protected onResize() {
    /* Override me */
  }

  /**
   * Lifecycle method called when the form of the modal has been submitted
   *
   * @param [event] - the submit event
   */
  protected onSubmit(event: SubmitEvent) {
    /* Override me */
  }

  /**
   * Displays an alert.
   *
   * @param [options] - If undefined or if neither title nor message are given, the alert is reset.
   * @param [options.title] - The title of the alert
   * @param [options.message] - The more detailed message of the alert
   * @param [options.type] - The alert type
   */
  setAlert(options?: AlertOptions): void;
  /** @deprecated Use setAlert(options) instead. */
  setAlert(title?: string, message?: string, type?: AlertType): void;
  setAlert(titleOrOptions?: string | AlertOptions, message?: string, type?: AlertType) {
    let title: string | undefined;
    if (titleOrOptions && typeof titleOrOptions === 'object') {
      ({ title, message, type } = titleOrOptions);
    } else {
      title = titleOrOptions;
    }

    this.#alert?.remove();
    this.#alert = undefined;

    if (title || message) {
      const attrs = {
        attrs: {
          title: title ?? '',
          message: message ?? '',
          type: type ?? 'info',
          'data-testid': 'modal-alert',
        },
      };
      this.#alert = h(`d-alert.${COMPONENT_NAME}--alert`, attrs);
    }

    if (this.#alert) {
      // Append the alert right before the footer wherever it is. This allows to move the footer
      // to a different position in the modal.
      this.footer.before(this.#alert);
    }
  }

  hasAlert() {
    return this.#alert != null;
  }

  resetAlert() {
    this.setAlert('');
  }

  /**
   * @param value - Whether the modal should be in fullscreen mode
   */
  set fullscreen(value) {
    this.#fullscreen = value;
    this.#applyFullscreen();

    this.querySelectorAll<HTMLButtonElement>('.fullscreen').forEach((fullscreenButton) => {
      fullscreenButton.title = value ? EXIT_FULLSCREEN_TITLE : FULLSCREEN_TITLE;
    });
  }

  #applyFullscreen() {
    this.dialog.classList.toggle('is-fullscreen', this.#fullscreen || mediaDialogXsOnly.matches);
    this.onResize();
  }

  /**
   * @return Whether the modal is in fullscreen mode
   */
  get fullscreen() {
    return this.dialog.classList.contains('is-fullscreen');
  }

  protected get submitButton() {
    return this.footer.querySelector('[type=submit]') as HTMLButtonElement;
  }

  protected get cancelButton() {
    return this.footer.querySelector('.cancel[type=button]') as HTMLButtonElement;
  }

  /**
   * Creates the header of the modal
   *
   * @param [titleIcon] - the icon of the header
   * @param [title] - the title of the header
   * @param [cancelXButton] - Whether to add a cancel "x" button
   * @return the header element
   */
  protected createHeader(titleIcon?: Icon, title = '', cancelXButton = true) {
    const header = h(`header.${COMPONENT_NAME}--header.d-compact-header`);
    if (title) {
      if (titleIcon) {
        header.append(titleIcon);
      }
      header.append((this.#title = h(`h1.${COMPONENT_NAME}--title.d-compact-header--title`, title)));
      if (cancelXButton) {
        header.append(createCancelXButton());
      }
    }
    return header;
  }
}

customElements.define(COMPONENT_NAME, Modal);

function createCancelXButton() {
  return createIconButton(icon(closeIcon), addFormattedHotkeyToTooltip(i18n('close'), HOTKEYS.GENERAL_CLOSE_MODAL), {
    variant: 'borderless',
    size: 's',
    styleClass: 'cancel',
  });
}
