import { createAbortError } from 'cadenza/api-client/abort-controller/abort-error';
import { timeout } from 'cadenza/utils/timeout';
import { debounce } from 'cadenza/utils/debounce';

type ProgressListener = (progressPercent: number) => void;

export class PromiseWithProgress<T> extends Promise<T> {
  #progressListeners: ProgressListener[] = [];

  progress(progressListener: ProgressListener) {
    this.#progressListeners.push(progressListener);
    return this;
  }

  notify(progressPercent: number) {
    this.#progressListeners.forEach((progressListener) => progressListener(progressPercent));
  }

  // Signature types copied from lib.es5.d.ts
  override then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: unknown) => TResult2 | PromiseLike<TResult2>) | undefined | null,
  ): PromiseWithProgress<TResult1 | TResult2> {
    const promise = super.then(onfulfilled, onrejected) as PromiseWithProgress<TResult1 | TResult2>;
    promise.progress = (progressListener) => {
      this.progress(progressListener);
      return promise;
    };
    return promise;
  }
}

/**
 * Debounce a function, and return a promise that will resolve to the result of the function
 * when it is really called.
 *
 * The lodash debounce function doesn't return anything, so it's not possible to get the result
 * of the debounced function.
 *
 * This works well to debounce a function that already returns a promise, e.g. a REST API call.
 *
 * @param func - The function to debounce.
 * @param [wait=0] - The number of milliseconds to delay.
 * @return Returns the new debounced function.
 */
export function debounceReturningPromise(func: (...args: unknown[]) => unknown, wait = 0) {
  function caller(resolve: (value: unknown) => void, reject: (error: unknown) => void, ...args: unknown[]) {
    try {
      resolve(func(...args));
    } catch (error) {
      reject(error);
    }
  }

  const debouncedCaller = debounce(caller, wait);

  return (...args: unknown[]) => {
    return new Promise((resolve, reject) => {
      debouncedCaller(resolve, reject, ...args);
    });
  };
}

const DELAY_TIME = 500;
export const DELAY_THRESHOLD = 200;

interface DelayOptions {
  /** A function returning a boolean whether the user aborted the action */
  isAborted?: () => boolean;
  /** Whether to delay only after a certain threshold: If the action takes only a little time, the promise is not delayed. */
  useThreshold?: boolean;
  /** Delay time to use. Falls back to 500ms */
  delayTime?: number;
}

/**
 * Delays the resolution / rejection of the given promise for a fixed amount of time (500ms;
 * changeable in options).
 *
 * The use case is to show the progress of an action for at least that time, even though the action
 * took less time, so that we avoid for example a "flashing" progress spinner. The problem cannot be
 * solved _within_ the progress spinner (or whatever progress display), because the UI would be
 * inconsistent if only the progress spinner is delayed, but not the rest of the UI.
 *
 * @param promise - The promise to delay
 * @param [options] - delay options
 * @return The delayed promise
 */
export async function delay<T = unknown>(promise: Promise<T>, options?: DelayOptions): Promise<T>;
export async function delay<T = unknown>(promise: Promise<T>, isAborted: () => boolean): Promise<T>;
export async function delay<T = unknown>(
  promise: Promise<T>,
  options: DelayOptions | (() => boolean) = {},
): Promise<T> {
  if (typeof options === 'function') {
    options = { isAborted: options };
  }
  const { isAborted = () => false, useThreshold = false } = options;
  const startTime = Date.now();
  let result, error;
  try {
    result = await promise;
  } catch (e) {
    error = e;
  }
  const elapsedTime = Date.now() - startTime;
  const delaytime = options.delayTime ?? DELAY_TIME;
  if (elapsedTime <= delaytime && (!useThreshold || elapsedTime > DELAY_THRESHOLD)) {
    await timeout(delaytime - elapsedTime);
  }
  if (isAborted()) {
    throw createAbortError();
  }
  if (error) {
    throw error;
  }
  return result as T;
}

export type LazyInit<T> = T | (() => T | Promise<T>);

/**
 * Returns a function to get a value lazily.
 *
 * @param init - The value or a function that either returns it or a Promise for it.
 * @param options - Options
 * @param [options.once] - Whether to initialize the value only once.
 * @return A function that returns a Promise for the value
 * @example
 *   const loadData: () => Promise<Item[]>;
 *   const getDataLazy = lazy(() => loadData()); // loadData() is called only once.
 *   getDataLazy().then((data) => { ... }); // data: Item[]
 *   getDataLazy().then((data) => { ... });
 */
export function lazy<T>(init: LazyInit<T>, { once = true } = {}): () => Promise<T> {
  if (!once) {
    return () => wrapInPromise(init);
  }

  let value: Promise<T> | null = null;
  return () => {
    if (!value) {
      value = wrapInPromise(init);
      value.catch(() => {
        value = null;
      });
    }
    return value;
  };
}

function wrapInPromise<T>(init: LazyInit<T>): Promise<T> {
  if (typeof init === 'function') {
    try {
      // https://github.com/microsoft/TypeScript/issues/37663
      return Promise.resolve((init as () => T | Promise<T>)());
    } catch (error) {
      return Promise.reject(error);
    }
  } else {
    return Promise.resolve(init);
  }
}

/**
 * Takes a list of promise predicates and returns true if any of them returns true, false otherwise.
 * This is a short circuit operation, e.g. it returns immediately if any of the promises returns true.
 *
 * @param checks a list of promise predicates to check
 */
export async function asyncSome(checks: Promise<boolean>[]): Promise<boolean> {
  if (!checks.length) {
    return Promise.resolve(false);
  }
  return new Promise((resolve) => {
    let rejectionCounter = 0;
    const reject = () => {
      rejectionCounter++;
      if (rejectionCounter === checks.length) {
        resolve(false);
      }
    };
    checks.forEach((check) =>
      check
        .then((value) => {
          if (value) {
            resolve(true);
          }
          // we reject in any case. If the promise was resolved previously, this is a no-op.
        })
        .finally(() => reject()),
    );
  });
}
