let EMPTY_DIV: HTMLDivElement;

/**
 * Creates an svg element containing the SVG markup received as argument.
 *
 * @param svgMarkup - Valid SVG markup, typically imported from a SVG file
 * @param role - The ARIA role to use for the SVG element
 * @return An svg element containing the SVG markup received as argument
 */
export function parseSvg(svgMarkup: string, role: 'img' | 'presentation' = 'presentation') {
  // For better performance (we have many icons), we use innerHTML,
  // which is four times faster than DOMParser.
  EMPTY_DIV = EMPTY_DIV || document.createElement('div');
  EMPTY_DIV.innerHTML = svgMarkup;
  const svgElement = EMPTY_DIV.firstElementChild as SVGSVGElement;
  svgElement.remove(); // Disconnect from the div
  svgElement.setAttribute('role', role);
  return svgElement;
}
