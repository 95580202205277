export interface AbortError extends DOMException {
  name: 'AbortError';
}

/**
 * _Note:_ This is implemented as a factory function and not as a class constructor
 * to enforce the usage of {@link #isAbortError}, which - in contrast to an `instanceof` check
 * for such a class - also supports "native" `AbortError`s.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/DOMException
 */
export function createAbortError(message = 'Aborted') {
  const error = new DOMException(message, 'AbortError');
  /*
   * If this is not nullified, printing the error will display the stack where this object was created,
   * not the stack where the exception was logged, which is confusing.
   */
  error.stack = undefined;
  return error as AbortError; // by contract
}

export function isAbortError(error: unknown): error is AbortError {
  return error instanceof DOMException && error.name === 'AbortError';
}
