// https://social.msdn.microsoft.com/Forums/en-US/edde0150-9478-4ca6-ba0f-9e084b5a4719/how-to-detect-microsoft-edge-chromium-chrome-ie-browser-using-javascript?forum=iewebdevelopment
export const isChromiumBrowser = (() => {
  const ua = navigator.userAgent.toLowerCase();
  return (ua.includes('edg') && !ua.includes('edge')) || ua.includes('chrome');
})();

export const isEdge = navigator.userAgent.includes('Edge');

export const isSafari = navigator.vendor.includes('Apple');

export const isIOS = navigator.userAgent.includes('iPad');

const chromiumMajorVersionAsString = /Chrome\/([0-9]+)/.exec(navigator.userAgent)?.[1];
/**
 * Major browser version for Chromium-based browser (Chrome/Chromium/Edge); undefined if not a
 * Chromium-based browser.
 */
export const chromiumMajorVersion =
  chromiumMajorVersionAsString !== undefined ? Number(chromiumMajorVersionAsString) : undefined;
