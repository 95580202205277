// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { default as lodashThrottle, type ThrottleSettings } from 'lodash-es/throttle';

import { DEFAULT_WAIT } from 'cadenza/utils/debounce';

/**
 * Wrapper around _.throttle to enforce our DEFAULT_WAIT
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- The function signature used (including `any`) is a direct copy from Lodash's `throttle` signature.
export function throttle(func: (...args: any) => any, wait = DEFAULT_WAIT, options?: ThrottleSettings) {
  return lodashThrottle(func, wait, options);
}
